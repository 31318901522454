document.addEventListener('DOMContentLoaded', () => {

  /** Инициализация слайдеров swiper */
  new Swiper('.slider__swiper-container', {
    slidesPerView: 1,
    initialSlide: 0,
    loop: true,
    autoplay: {
      delay: 6000,
    },
    speed: 1500,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    preloadImages: false,
    lazy: true,
    loadOnTransitionStart: true
  });

  /** Переключение кнопки вверх при изменении позиции скролла */
  toggleBtnUp();
  window.addEventListener('scroll', toggleBtnUp);

  /** Переключение фона хедера при изменении позиции скролла */
  const header = document.querySelector('.header');
  colorizeHeader(header);
  window.addEventListener('scroll', () => colorizeHeader(header));

  /** Плавная прокрутка до якоря */
  const anchorsHref = document.querySelectorAll('[href^="#"]');
  const anchorsDataHref = document.querySelectorAll('[data-href^="#"]');
  const anchors = [...anchorsHref, ...anchorsDataHref];
  const speed = 0.3;
  anchors.forEach(anchor => {
    anchor.addEventListener('click', event => {
      event.preventDefault();

      const yOffset = window.pageYOffset;
      const arrowUpWrapper = event.target.closest('.arrow-up');

      const hash = arrowUpWrapper.href
        ? arrowUpWrapper.href.replace(/[^#]*(.*)/, '$1')
        : arrowUpWrapper.dataset.href.replace(/[^#]*(.*)/, '$1');
      const topPosition = document.querySelector(hash).getBoundingClientRect().top;

      let startTimestamp = null;

      requestAnimationFrame(step);

      function step(timestamp) {
        if (startTimestamp === null) startTimestamp = timestamp;
        const currentTimestamp = timestamp - startTimestamp;
        const headerHeight = document.querySelector('.header').clientHeight;
        const stopPosition =  yOffset + topPosition - headerHeight;

        const directionScroll =
          topPosition < 0
            ? Math.max(yOffset - currentTimestamp/speed, stopPosition)
            : Math.min(yOffset + currentTimestamp/speed, stopPosition);

        window.scrollTo(0, directionScroll);

        directionScroll !== stopPosition && requestAnimationFrame(step);
      }
    });
  });


  /** Мобильное меню */
  const btnOpenMobile = document.querySelector('[data-mobile-nav="open"]');
  const btnCloseMobileMenu = document.querySelector('[data-mobile-nav="close"]');
  const mobileMenu = document.querySelector('.nav-mobile__wrap');
  btnOpenMobile.addEventListener('click', () => mobileMenu.classList.add('open'));
  btnCloseMobileMenu.addEventListener('click', () => mobileMenu.classList.remove('open'));
  window.addEventListener('click', event => {
    const body = event.target.closest('.nav-mobile__body');
    const btnOpen = event.target.closest('[data-mobile-nav="open"]');
    if (body || btnOpen) return;

    mobileMenu.classList.remove('open');
  });


  /** Время */
  time();


  /** Диаграмма */
  const graphs = document.querySelectorAll('.graph');
  graphs.forEach((graph) => new Diagram(graph));
});

Date.prototype.format = function(format = 'yyyy-mm-dd') {
  const replaces = {
    yyyy: this.getFullYear(),
    yy: this.getFullYear().toString().slice(-2),
    mm: ('0'+(this.getMonth() + 1)).slice(-2),
    dd: ('0'+this.getDate()).slice(-2),
    hh: ('0'+this.getHours()).slice(-2),
    MM: ('0'+this.getMinutes()).slice(-2),
    ss: ('0'+this.getSeconds()).slice(-2)
  };

  let result = format;

  for (const replace in replaces) {
    result = result.replace(replace,replaces[replace]);
  }

  return result;
};

function toggleBtnUp() {
  const currentScroll = document.documentElement.scrollTop;
  const arrowUp = document.querySelector('.arrow-up');

  currentScroll === 0
    ? arrowUp.classList.add('hidden')
    : arrowUp.classList.remove('hidden');
}

function colorizeHeader(header) {
  window.pageYOffset > 0
    ? header.classList.add('color')
    : header.classList.remove('color');
}

function time() {
  const monthName = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
  const daysWeekName = ['воскресение', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

  const timeWrap = document.querySelector('.aside');
  const time = timeWrap.querySelector('.aside__time');
  const date = timeWrap.querySelector('.aside__date');
  const dayWeek = timeWrap.querySelector('.aside__day-week');

  function changeTime() {
    const currentDate = new Date();

    time.innerHTML = currentDate.format('hh:MM');
    date.innerHTML = `${currentDate.format('dd')} ${monthName[currentDate.getMonth()]} ${currentDate.format('yyyy')}`;
    dayWeek.innerHTML = daysWeekName[currentDate.getDay()];
  }

  changeTime();
  setInterval(changeTime, 60000);
}

class Diagram {

  constructor(wrap) {
    this._body = wrap.querySelector('.graph__body');
    this._indicators = wrap.querySelector('.graph__indicators');
    this._data = JSON.parse(wrap.querySelector('.graph__hidden').textContent);

    this._init();
  }

  _init() {
    this._body.innerHTML = this._generateChart().join('');
    this._generateIndicators();
  }

  _generateIndicators() {
    const value = this._indicators.querySelector('.graph__indicators-value');
    value.innerHTML = this._data.totalValue[0];

    const description = this._indicators.querySelector('.graph__indicators-description');
    description.innerHTML = this._data.totalValue[1];
  }

  _generateHeights() {
    const maxValue = this._data.data.reduce((prevValue, {y}) => y > prevValue ? y : prevValue, 0);

    return this._data.data.map(({y}) => {
      return Math.round(y * 100 / maxValue);
    });
  }

  _generateChart() {
    const width = 100 / this._data.data.length;
    const heights = this._generateHeights();

    return this._data.data.map((data, index) => {
      return this._generateColumn(data, width, heights[index], this._data.unit);
    });
  }

  _generateColumn({x, y}, width, height, unit) {
    return `
      <div class="graph__column-wrap" style="width: ${width}%">
        <div class="graph__column" style="height: ${height}%">
          <div class="graph__value"><span>${y}</span><span>${unit}</span></div>
        </div>
        <div class="graph__line">
          <span>${x}</span>
        </div>
      </div>
    `;
  }
}


